export default function LogService($log, orbMessageService) {
  return {
    displayErrorMessage: (reason, defaultMessage) => {
      let message = '';

      if (reason && reason.data && reason.data.errors) {
        message = reason.data.errors.map(error => error.message).join('. ') + '.';
      } else if (reason && reason.data && reason.data.modelState) {
        for (let key in reason.data.modelState) {
          if (reason.data.modelState.hasOwnProperty(key)) {
            message += reason.data.modelState[key] + ". ";
          }
        }
      }

      if (!message) {
        message = defaultMessage;
      }

      $log.error(message);
      orbMessageService.error(message);
    }
  };
}
