export default function AttributeEditController($routeParams, $location, $log, $q, $mdDialog, vaxAttributeService, logService) {
  this.attributeId = $routeParams.id;
  this.data = {};

  this.loadData = () => {
    if (!this.attributeId) {
      this.data = vaxAttributeService.empty();
    } else {
      vaxAttributeService.get(this.attributeId).then(response => {
        angular.copy(response, this.data);
        this.title = this.data.name;
      }, reason => {
        logService.displayErrorMessage(reason, 'The Attribute you requested was not found.');
      });
    }
  };

  this.updateAttribute = (values) => {
    if (this.data.typeCode == 2) {
      if (this.data.minValue === null || this.data.minValue === undefined || this.data.maxValue === null || this.data.maxValue === undefined || this.data.minValue > this.data.maxValue) {
        return;
      }
    }

    let defer = $q.defer();

    values = angular.extend({}, {
      id: this.data.id,
      name: this.data.name,
      description: this.data.description,
      typeCode: this.data.typeCode,
      categoryCode: this.data.categoryCode,
      isInverse: this.data.isInverse,
      minValue: this.data.minValue,
      maxValue: this.data.maxValue
    }, values);

    vaxAttributeService.save(values).then(() => {
      $log.info(values);
      this.data = angular.extend(this.data, values);
      this.title = this.data.name;
      defer.resolve();
    }, reason => {
      $log.error(reason);
      defer.reject(reason.data); // prevent update to local model
    });

    return defer.promise;
  };

  this.deleteAttribute = (ev) => {
    let deleteConfirm = $mdDialog.confirm()
      .title('Delete attribute?')
      .textContent(`All data associated with this attribute will be permanently deleted. Previously-executed analysis reports that included this attribute will not be affected.`)
      .ariaLabel('Delete attribute?')
      .css('md-confirm-dialog')
      .targetEvent(ev)
      .ok('Confirm')
      .cancel('Cancel');

    $mdDialog.show(deleteConfirm).then(() => {
      vaxAttributeService.delete(this.data).then( () => $location.path('/attributes'));
    }, () => {
      // TODO: something went wrong. Log it.
    });
  };

  this.openMenu = ($mdMenu, $event) => {
    $mdMenu.open($event);
  };

  this.loadData();
}
