export default function IdentityController($scope, $log, $window, $mdDialog, identityService) {
  let pendingLogin = false;
  this.user = identityService.data;
  identityService.ensure();

  let showLogin = (ev) => {
    pendingLogin = true; // just in case somebody bypasses the 401 auth event and uses the Login button

    let loginModalInstance = $mdDialog.show({
      templateUrl:  'templates/dialog/login.html',
      controller:   'vaxLoginModalController',
      controllerAs: 'loginController',
      parent: angular.element($window.document.body),
      targetEvent: ev
    });

    return loginModalInstance;
  };

  $scope.$on('event:auth-loginRequired', (e) => {
    if (!pendingLogin) {
      pendingLogin = true;
      showLogin(e);
    }
  });

  $scope.$on('event:auth-loginConfirmed', () => {
    $log.info('Login confirmed');
    pendingLogin = false;
    identityService.refresh();
  });

  $scope.$on('event:auth-loginCancelled', () => {
    $log.info('Login cancelled');
    pendingLogin = false;
  });
}
