export default function ReportCitationsController(_, $log, $mdDialog, analysisId, vaxReportService, vaxAnalyzeProfileService, logService) {
  this.analysisId = analysisId;
  this.analysis = {};

  vaxReportService.getDetails(this.analysisId).then((response) => {
    angular.copy(response, this.analysis);
  }, (reason) => {
    $log.error(reason);
  });

  this.addProfileCitation = ($event, form, entity, citation) => {
    vaxAnalyzeProfileService.addCitation(entity, citation).then(response => {
      let newCitation = angular.copy(response);
      entity.citations.analysisProfile.push(newCitation);

      Object.keys(citation).forEach(function(key) { delete citation[key]; });
      form.$setPristine();
      form.$setUntouched();
    }, reason => {
      logService.displayErrorMessage(reason, 'Unable to add the Citation.');
    });
  };

  this.removeProfileCitation = ($event, entity, citation) => {
    vaxAnalyzeProfileService.removeCitation(entity, citation).then(() => {
      let index = entity.citations.analysisProfile.indexOf(citation);
      entity.citations.analysisProfile.splice(index, 1);
    }, reason => {
      logService.displayErrorMessage(reason, 'Unable to remove the Citation.');
    });
  };

  this.cancel = () => {
    return $mdDialog.cancel();
  };
}

