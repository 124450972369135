export default function ProfileController(identityService, vaxAccountProfileService, vaxOrganizationService, logService) {
  this.data = {user: {}, org: {}};
  vaxAccountProfileService.refresh();

  this.loadData = () => vaxOrganizationService.get().then(response => {
    this.data.user = vaxAccountProfileService.data;
    if (this.data.org !== response) {
      angular.copy(response, this.data.org);
    }
  }, reason => {
    logService.displayErrorMessage(reason, 'The Organization information could not be loaded');
  });

  this.updateUser = () => {
    vaxAccountProfileService.save().then(() => {
      identityService.refresh();
    });
  };

  this.changePassword = () => {
    identityService.changePassword({ newPassword: this.data.password });
  };

  this.updateOrganization = () => {
    vaxOrganizationService.save({ organization: this.data.org.organization });
  };

  this.loadData();
}
