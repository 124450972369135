export default function IdentityService($rootScope, $q, $location, $log, logService, vaxIdentityApi, vaxRegistrationApi, vaxAuthenticationApi, vaxAccountPasswordApi, authService, orbMessageService) {
  let identity = {};

  let refresh = () => {
    let defer = vaxIdentityApi.all();
    defer.then(updatedIdentity => {
      if (updatedIdentity !== identity) {
        angular.copy(updatedIdentity, identity);
      }
    }, reason => {
      if (reason && reason.status == 401) {
        return;
      }

      logService.displayErrorMessage(reason, 'Could not load user information.');
    });

    return defer;
  };

  let ensure = () => {
    if (identity && Object.keys(identity).length > 0) {
      return $q.when(identity);
    }

    return refresh();
  };

  return {
    data: identity,
    ensure: ensure,
    refresh: refresh,

    login: data => {
      data = data || {};
      let auth = vaxAuthenticationApi.login({
        email: data.email,
        password: data.password,
        persistent: data.persistent || false
      });

      auth.then(() => {
        authService.loginConfirmed();
      }, reason => {
        logService.displayErrorMessage(reason, 'Authentication failed.');
      });

      return auth;
    },

    logout: () => {
      let auth = vaxAuthenticationApi.logout();

      auth.then(() => {
        if (identity !== {}) {
          angular.copy({}, identity);
        }

        $location.path('/');
        $rootScope.$broadcast('event:auth-logoutConfirmed', {});
        if (!$rootScope.$$phase) {
          $rootScope.$apply();
        }

        orbMessageService.success('You have been successfully signed out.');
        refresh();
      }, reason => {
        logService.displayErrorMessage(reason, 'An error occurred while signing out.');
      });

      return auth;
    },

    register: data => {
      data = data || {};
      let registration = vaxRegistrationApi.register({
        email: data.email,
        password: data.password,
        fullName: data.fullName
      });

      registration.then(() => {
        authService.loginConfirmed();
      }, reason => {
        logService.displayErrorMessage(reason, 'Registration failed.');
      });

      return registration;
    },

    changePassword: (creds) => {
      let auth = vaxAccountPasswordApi.changePassword(creds);

      auth.then(() => {
        orbMessageService.success('Password updated.');
      }, reason => {
        logService.displayErrorMessage(reason, 'An error occurred while changing your password.');
      });

      return auth;
    }
  };
}
