export default function AnalysisAttributeSelectController(analysisId, $scope, $mdDialog, $location, vaxAnalyzeAttributeService, logService) {
  this.analysisId = analysisId;
  this.data = {};

  this.checkboxes = {
    count: 0,
    items: {}
  };

  let enableAll = () => {
    angular.forEach(this.data.attributes, (attribute) => attribute.disabled = false);
  };

  $scope.$watch(() => {
    return this.checkboxes.items;
  }, () => {
    let checkedCount = 0;
    angular.forEach(this.data.attributes, (attribute) => {
      if (this.checkboxes.items[attribute.id]) {
        checkedCount++;
      }
    });

    enableAll();

    this.checkboxes.count = checkedCount;
  }, true);

  this.save = () => {
    return $mdDialog.hide(this.checkboxes.items);
  };

  this.cancel = () => {
    return $mdDialog.cancel();
  };

  vaxAnalyzeAttributeService.all(this.analysisId).then(response => {
    if (this.data !== response) {
      angular.copy(response, this.data);
    }
  }, reason => {
    logService.displayErrorMessage(reason, 'The Attributes could not be loaded.');
  });
}
