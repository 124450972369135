export default function ReportService(vaxAnalysisApi, vaxReportApi, vaxReportDetailsApi, logService, orbMessageService) {
  return {
    get: (id) => {
      let defer = vaxReportApi.find(id);
      return defer;
    },

    getDetails: (id) => {
      let defer = vaxReportDetailsApi.find(id);
      return defer;
    },

    create: (report) => {
      let defer = vaxReportApi.save(report);

      defer.then(() => {
        orbMessageService.success('Report posted for processing.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred posting this Report for processing.');
      });

      return defer;
    },

    save: (report) => {
      let defer = vaxReportApi.save(report);

      defer.then(() => {
        // successful
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred updating this Report.');
      });

      return defer;
    },

    delete: (report) => {
      let defer = vaxAnalysisApi.delete(report.id);

      defer.then(() => {
        //orbMessageService.success('Report deleted.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred deleting this report.');
      });

      return defer;
    }
  };
}
