export function IdentityApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/account/identity', {}, {all: {isArray: false}});
}

export function RegistrationApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/account/register', {}, {register: { method: 'POST' }});
}

export function AuthenticationApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/account/auth', {}, {
    login: { method: 'POST' },
    logout: { method: 'DELETE' }
  });
}

export function AttributeApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/attributes/:id', {id: '@id'}, {all: {isArray: false}});
}

export function AnalyzeProfileApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/analyze/profiles/:id', {id: '@id'}, {
    all: {isArray: false},
    addCitation: { method: 'POST', url: '/api/analyze/profiles/:id/citations' },
    removeCitation: { method: 'DELETE', url: '/api/analyze/profiles/:id/citations/:citationId' }
  });
}

export function AnalyzeAttributeApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/analyze/attributes/:id', {id: '@id'}, {all: {isArray: false}});
}

export function AnalyzeValueApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/analyze/values/:id', {id: '@id'}, {});
}

export function AnalyzeCloneApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/analyze/clone', {}, {});
}

export function AnalyzeFilterApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/analyze/implied', {}, {all: {isArray: false}});
}

export function AnalysisProfileListApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/analyze/profilelist/:id', {id: '@id'}, {});
}

export function AnalysisAttributeListApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/analyze/attributelist/:id', {id: '@id'}, {});
}

export function AnalysisApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/analyses/:id', {id: '@id'}, {
    all: {isArray: false},
    addNote: { method: 'POST', url: '/api/analyses/:analysisId/notes/:id' },
    updateNote: { method: 'POST', url: '/api/analyses/:analysisId/notes/:id' },
    removeNote: { method: 'DELETE', url: '/api/analyses/:analysisId/notes/:id' }
  });
}

export function ReconfigureApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/report/reconfigure/:id', {id: '@id'}, {all: {isArray: false}});
}

export function ReportDetailsApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/reports/details/:id', {id: '@id'}, {all: {isArray: false}});
}

export function ReportApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/reports/:id', {id: '@id'}, {all: {isArray: false}});
}

export function AccountProfileApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/account/profile', {}, {all: {isArray: false}});
}

export function AccountOrganizationApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/account/organization', {}, {all: {isArray: false}});
}

export function AccountPasswordApi(orbRestfulProgressService) {
  return new orbRestfulProgressService('/api/account/password', {}, {changePassword: { method: 'PUT' }});
}

