export default function RouteProvider($routeProvider) {

  return $routeProvider
    .when('/', {
      templateUrl:  'templates/welcome.html',
      controller:   'vaxWelcomeController',
      controllerAs: 'welcomeController'
    })
    .when('/account', {
      templateUrl:  'templates/account.html',
      controller:   'vaxAccountController',
      controllerAs: 'accountController'
    })
    .when('/settings/organization', {
      templateUrl:  'templates/settings/organization.html',
      controller:   'vaxOrganizationController',
      controllerAs: 'organizationController'
    })
    .when('/analyze/load', {
      templateUrl:  'templates/analysis-list.html',
      controller:   'vaxAnalysisListController',
      controllerAs: 'analysisListController'
    })
    .when('/analyze/:analysisId?', {
      templateUrl:  'templates/analysis-edit.html',
      controller:   'vaxAnalysisEditController',
      controllerAs: 'analysisEditController'
    })
//  .when('/account/register', {
//    templateUrl:  'templates/register.html',
//    controller:   'vaxRegisterController',
//    controllerAs: 'registerController'
//  })
    .when('/account/login', {
      templateUrl:  'templates/login.html',
      controller:   'vaxLoginController',
      controllerAs: 'loginController'
    })
    .when('/attributes', {
      templateUrl:  'templates/attribute-list.html',
      controller:   'vaxAttributeListController',
      controllerAs: 'attributeListController'
    })
    .when('/attributes/:id', {
      templateUrl:  'templates/attribute-edit.html',
      controller:   'vaxAttributeEditController',
      controllerAs: 'attributeEditController'
    })
    .when('/report/:reportId', {
      templateUrl:  'templates/report.html',
      controller:   'vaxReportController',
      controllerAs: 'reportController'
    });
}
