import "@babel/polyfill";

import RouteProvider from './routes';
import ThemingProvider from './theming';

import * as res from './resources';
import * as directives from './directives';

import IdentityController from './controllers/identity';
import RegisterController from './controllers/register';
import LoginController from './controllers/login';
import LoginModalController from './controllers/login-modal';
import WelcomeController from './controllers/welcome';
import AttributeListController from './controllers/attribute-list';
import AttributeEditController from './controllers/attribute-edit';
import ItemCreateController from './controllers/item-create';
import ItemRenameController from './controllers/item-rename';
import AnalysisListController from './controllers/analysis-list';
import AnalysisEditController from './controllers/analysis-edit';
import AnalysisProfileCreateController from './controllers/analysis-profile-create';
import AnalysisAttributeSelectController from './controllers/analysis-attribute-select';
import ReportController from './controllers/report';
import ReportCitationsController from './controllers/report-citations';
import ReportNotesController from './controllers/report-notes';
import ReportReconfigureController from './controllers/report-reconfigure';
import ReportDetailsController from './controllers/report-details';
import AccountController from './controllers/account';

import IdentityService from './services/identity';
import LogService from './services/log';
import AttributeService from './services/attribute';
import AnalysisService from './services/analyze/analysis';
import AnalyzeProfileService from './services/analyze/profile';
import AnalyzeAttributeService from './services/analyze/attribute';
import AnalyzeValueService from './services/analyze/value';
import ReportService from './services/report';
import ComputationMonitorService from './services/computationMonitor';
import AccountProfileService from './services/profile';
import AccountOrganizationService from './services/organization';

export default angular
  .module('priorityVax', [
    'ngAnimate',
    'http-auth-interceptor',
    'ngRoute',
    'ngResource',
    'ngMessages',
    'ngAria',
    'ngSanitize',
    'ngMaterial',
    'as.sortable',
    'orbMessage',
    'orbProgress',
    'orbResource'
  ])
  .config(RouteProvider)
  .config(ThemingProvider)
  .config(function($logProvider) {
    $logProvider.debugEnabled(true);
  })
  .config(function($mdIconProvider) {
    $mdIconProvider.defaultIconSet('img/app.svg', 24);
  })
  .run(function($templateRequest) {
    // Pre-fetch icons sources by URL and cache in the $templateCache...
    // subsequent $templateRequest calls will look there first.
    let urls = [ 'img/app.svg' ];

    angular.forEach(urls, function(url) {
      $templateRequest(url);
    });
  })
  .config(function($resourceProvider) {
    $resourceProvider.defaults.actions = {
      all:    {method: 'GET', isArray:true},
      find:   {method: 'GET'},
      create: {method: 'POST'},
      update: {method: 'PUT'},
      delete: {method: 'DELETE'}
    };
  })
  .config(function($compileProvider){
      $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|javascript):/);
  })
  .factory('_', function($window) {
    return $window._;
  })
  .factory('signalR', function($window) {
    return $window.signalR;
  })
  .controller('vaxIdentityController', IdentityController)
  .controller('vaxRegisterController', RegisterController)
  .controller('vaxLoginController', LoginController)
  .controller('vaxLoginModalController', LoginModalController)
  .controller('vaxWelcomeController', WelcomeController)
  .controller('vaxAttributeListController', AttributeListController)
  .controller('vaxAttributeEditController', AttributeEditController)
  .controller('vaxItemCreateController', ItemCreateController)
  .controller('vaxItemRenameController', ItemRenameController)
  .controller('vaxAnalysisListController', AnalysisListController)
  .controller('vaxAnalysisEditController', AnalysisEditController)
  .controller('vaxAnalysisProfileCreateController', AnalysisProfileCreateController)
  .controller('vaxAnalysisAttributeSelectController', AnalysisAttributeSelectController)
  .controller('vaxReportController', ReportController)
  .controller('vaxReportCitationsController', ReportCitationsController)
  .controller('vaxReportNotesController', ReportNotesController)
  .controller('vaxReportReconfigureController', ReportReconfigureController)
  .controller('vaxReportDetailsController', ReportDetailsController)
  .controller('vaxAccountController', AccountController)
  .factory('identityService', IdentityService)
  .factory('logService', LogService)
  .factory('vaxAttributeService', AttributeService)
  .factory('vaxAnalysisService', AnalysisService)
  .factory('vaxAnalyzeProfileService', AnalyzeProfileService)
  .factory('vaxAnalyzeAttributeService', AnalyzeAttributeService)
  .factory('vaxAnalyzeValueService', AnalyzeValueService)
  .factory('vaxReportService', ReportService)
  .factory('vaxComputationMonitor', ComputationMonitorService)
  .factory('vaxAccountProfileService', AccountProfileService)
  .factory('vaxOrganizationService', AccountOrganizationService)
  .factory('vaxIdentityApi', res.IdentityApi)
  .factory('vaxRegistrationApi', res.RegistrationApi)
  .factory('vaxAuthenticationApi', res.AuthenticationApi)
  .factory('vaxAttributeApi', res.AttributeApi)
  .factory('vaxAnalyzeProfileApi', res.AnalyzeProfileApi)
  .factory('vaxAnalyzeAttributeApi', res.AnalyzeAttributeApi)
  .factory('vaxAnalyzeValueApi', res.AnalyzeValueApi)
  .factory('vaxAnalyzeCloneApi', res.AnalyzeCloneApi)
  .factory('vaxAnalyzeFilterApi', res.AnalyzeFilterApi)
  .factory('vaxAnalysisProfileListApi', res.AnalysisProfileListApi)
  .factory('vaxAnalysisAttributeListApi', res.AnalysisAttributeListApi)
  .factory('vaxAnalysisApi', res.AnalysisApi)
  .factory('vaxReconfigureApi', res.ReconfigureApi)
  .factory('vaxReportApi', res.ReportApi)
  .factory('vaxReportDetailsApi', res.ReportDetailsApi)
  .factory('vaxAccountProfileApi', res.AccountProfileApi)
  .factory('vaxOrganizationApi', res.AccountOrganizationApi)
  .factory('vaxAccountPasswordApi', res.AccountPasswordApi)
  .directive('navPrimary', directives.NavigationPrimary)
  .directive('asMatchModel', directives.AsMatchModel)
  .directive('asLogout', directives.AsLogoutDirective)
  .filter('startFrom', directives.StartFromFilter)
  .filter('numberNoTrail', directives.NumberNoTrail)
  .filter('alphanumeric', directives.AlphanumericFilter)
  .filter('attributeCategory', directives.AttributeCategoryFilter)
  .filter('isEmpty', directives.IsEmptyFilter)
  .filter('yesNo', directives.YesNoFilter);

