export default function ComputationMonitorService($rootScope, $q, $log, signalR) {
  let hub, start, stop, subscribeTo;

  hub = new signalR.HubConnectionBuilder()
    .withUrl("/computationHub")
    .configureLogging(signalR.LogLevel.Information)
  //.withAutomaticReconnect()
    .build();

  hub.on('onAnalysisComplete', id => {
    $log.debug("Analysis has completed for Report " + id);
    $rootScope.$emit('event:computation-analysisComplete', id);
  });
  hub.on('onSubscribed', id => {
    $log.debug("Subscribed to " + id);
  });
  hub.onclose(err => {
    $log.error(err);
    $log.info('Disconnected from computationHub');
  });
  hub.onreconnecting(err => {
    $log.error(err);
    $log.debug('Reconnecting to computationHub');
  });
  hub.onreconnected(connectionId => {
    $log.debug('Reconnected to computationHub with connection: ' + connectionId);
  });

  $rootScope.$on('event:auth-logoutConfirmed', () => {
    hub.stop();
  });

  start = function() {
    try {
      $log.debug('Connecting to computationHub');
      let promise = hub.start();
      $log.info('Connected to computationHub');
      return promise;
    } catch (err) {
      $log.error(err);
    }
  };

  subscribeTo = function (analysisId, scope, callback) {
    let handler = $rootScope.$on('event:computation-analysisComplete', function analysisChanged(event, data) {
      if (data == analysisId) {
        callback(data);
      }
    });
    scope.$on('$destroy', handler);

    return $q.when(start()).then(() => {
      $log.debug('Subscribing to ' + analysisId);
      try {
        hub.invoke("SubscribeTo", analysisId);
      } catch (err) {
        $log.error(err);
      }
    });
  };

  stop = function () {
    hub.stop();
  };

  return {
    start: start,
    subscribeTo: subscribeTo,
    stop: stop
  };
}
