export default function AnalysisService(vaxAnalysisApi, vaxReconfigureApi, vaxAnalyzeCloneApi, logService, orbMessageService) {
  return {
    all: () => {
      let defer = vaxAnalysisApi.all();
      return defer;
    },

    get: (id) => {
      let defer = vaxAnalysisApi.find(id);
      return defer;
    },

    getForReconfigure: (id) => {
      let defer = vaxReconfigureApi.find(id);
      return defer;
    },

    save: (analysis) => {
      let defer = vaxAnalysisApi.save(analysis);

      defer.then(() => {
        // successful
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred updating the state of the analysis.');
      });

      return defer;
    },

    cloneFrom: (source) => {
      let defer = vaxAnalyzeCloneApi.save({sourceAnalysisId: source.id});

      defer.then ((response) => {
        orbMessageService.success ('New analysis successfully cloned.');
        return response;
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred attempting to clone an analysis from an existing template.');
      });

      return defer;
    },

    delete: (analysis) => {
      let defer = vaxAnalysisApi.delete(analysis.id);

      defer.then(() => {
        //orbMessageService.success('Analysis deleted.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred deleting this analysis.');
      });

      return defer;
    },

    addNote: (analysis, note) => {
      let defer = vaxAnalysisApi.addNote({analysisId: analysis.id}, note);

      defer.then(() => {
        //orbMessageService.success('Analysis Profile citation added.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred adding this note.');
      });

      return defer;
    },

    removeNote: (analysis, note) => {
      let defer = vaxAnalysisApi.removeNote({analysisId: analysis.id, id: note.id});

      defer.then(() => {
        //orbMessageService.success('Analysis Profile citation remove.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred removing this note.');
      });

      return defer;
    }
  };
}
