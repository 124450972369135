export default function AnalysisEditController(_, $routeParams, $log, $window, $mdDialog, $location, $q, vaxAnalysisService, vaxAnalyzeProfileService, vaxAnalyzeAttributeService, vaxAnalyzeValueService, vaxReportService, logService) {
  this.analysisId = $routeParams.analysisId;
  this.analysis = {};
  this.userAttributes = [];
  this.percentComplete = 0;
  this.hasMissingValues = true;
  this.hasVisitedValuesTab = false;

  this.attributeMouseEvents = {};

  this.hasWeightsError = false;

  this.isAnalysisMissing = false;

  this.openMenu = ($mdMenu, $event) => {
    $mdMenu.open($event);
  };

  this.refreshData = () => {
    vaxAnalysisService.get(this.analysisId).then((response) => {
      angular.copy(response, this.analysis);
      angular.copy(response.attributes.filter(attribute => [1,2].indexOf(attribute.typeCode) >= 0), this.userAttributes);

      this.analysis.profiles.forEach(profile => {
        this.reconcileProfileConfiguration(profile);
      });

      this.checkForErrors();

      this.updatePercentComplete();

      this.checkIsCentroid(false);
    }, (reason) => {
      logService.displayErrorMessage(reason, 'The Analysis you requested was not found.');
      if (angular.isDefined(reason) && reason.status === 404) {
        this.isAnalysisMissing = true;
      }
    });
  };

  if (this.analysisId) {
    this.refreshData();
  } else {
    this.analysis = {
      isCentroid: false,
      profiles: [],
      attributes: []
    };
  }

  this.reconcileProfileConfiguration = (profile) => {
    if (!profile.displayConfig) {
      profile.displayConfig = {};
    }

    profile.displayConfig.name = profile.name;
    profile.displayConfig.description = profile.description;
  };

  this.checkForErrors = () => {
    this.hasWeightsError = this.analysis.attributes.length && this.analysis.attributes.reduce((sum, item) => sum + item.weight, 0) !== 100;
  };

  this.updatePercentComplete = () => {
    this.percentComplete = 0;

    if (this.analysis.profiles.length) { this.percentComplete += 50; }
    if (this.analysis.attributes.length) { this.percentComplete += 50; }

    let hasUnsetValues = false;
    this.userAttributes.forEach(attrib => {
      if (this.analysis.profiles.some(profile => profile.values[attrib.attributeId] === null || profile.values[attrib.attributeId] === undefined)) {
        hasUnsetValues = true;
      }
    });
    if (!this.hasVisitedValuesTab && !this.userAttributes.length) { hasUnsetValues = true; }

    if (hasUnsetValues) { this.percentComplete -= 20; }
    if (this.hasWeightsError) { this.percentComplete -= 10; }

    this.hasMissingValues = hasUnsetValues;
  };

  this.updateProfileConfiguration = (analysisProfile, property, doValueUpdate, doSave) => {
    if (doValueUpdate) {
      analysisProfile[property] = analysisProfile.displayConfig[property];

      if (doSave) {
        this.saveConfiguration(analysisProfile);
      }
    }
  };

  this.toDefault = (analysisProfile, propertyName) => {
    if (analysisProfile.configuration[propertyName] !== null) {
      analysisProfile.configuration[propertyName] = null;
      this.reconcileProfileConfiguration(analysisProfile);

      for (let property in analysisProfile.configuration) {
        if (analysisProfile.configuration[property] === undefined) {
          return false;
        }
      }

      this.saveConfiguration(analysisProfile);
    }
  };

  this.saveConfiguration = (analysisProfile) => {
    let request = Object.assign({}, analysisProfile);
    request.analysisId = this.analysisId;

    vaxAnalyzeProfileService.save(request);
  };

  this.checkIsCentroid = (sendCentroidFlagToServer) => {
    if (sendCentroidFlagToServer) {
      vaxAnalysisService.save(this.analysis);
    }

    if (this.analysis.isCentroid) {
      this.analysis.attributes.forEach(attribute => {
        attribute.isLocked = true;
      });

      this.applyRankOrderCentroid();
    }
  };

  this.applyRankOrderCentroid = () => {
    if (!this.analysis.attributes.length) { return; }

    let numberOfItems = this.analysis.attributes.length;
    for (let i = 0; i < numberOfItems; i++) {
      let sigmaCounter = i+1;
      let weightNumerator = 0;
      while (sigmaCounter <= numberOfItems) {
        weightNumerator += (1 / sigmaCounter);
        sigmaCounter++;
      }
      let weightQuotient = weightNumerator * (1 / numberOfItems);
      this.analysis.attributes[i].weight = Math.floor(weightQuotient * 100);
    }

    let sumOfWeights = 0;
    for (let i = 0; i < numberOfItems; i++) {
      sumOfWeights += this.analysis.attributes[i].weight;
    }
    let difference = 100 - sumOfWeights;

    let index = 0;
    while (difference > 0) {
      this.analysis.attributes[index].weight += 1;
      difference--;
      index++;
    }

    this.updateAttributeList();
  };

  this.updateValues = (profile) => {
    let request = Object.assign({}, profile);
    request.analysisId = this.analysisId;

    vaxAnalyzeValueService.save(request).then(() => {
      this.updatePercentComplete();
    });
  };

  this.ensureValues = () => {
    this.hasVisitedValuesTab = true;

    this.userAttributes.forEach(attrib => {
      this.analysis.profiles.forEach(profile => {
        if (profile.values[attrib.attributeId] === null || profile.values[attrib.attributeId] === undefined) {
          if (attrib.typeCode === 1) {
            profile.values[attrib.attributeId] = 0;
          } else if (attrib.typeCode === 2) {
            profile.values[attrib.attributeId] = attrib.minValue;
          }

          this.updateValues(profile);
        }
      });
    });

    if (!this.userAttributes.length) { this.updatePercentComplete(); }
  };

  this.updateProfileList = () => {
    vaxAnalyzeProfileService.updateList({
      id: this.analysisId,
      profiles: this.analysis.profiles
    }).then(() => {
      this.updatePercentComplete();
    });
  };

  this.removeProfile = (analysisProfile) => {
    vaxAnalyzeProfileService.delete(analysisProfile).then(() => {
      this.analysis.profiles.splice(this.analysis.profiles.indexOf(analysisProfile), 1);
      this.checkForErrors();
      this.updatePercentComplete();
    });
  };

  this.setMouse = (id) => {
    this.attributeMouseEvents[id] = true;
  };

  this.updateWeights = (targetedAttribute) => {
    if (targetedAttribute.weight === undefined) {
      return;
    }
    if (targetedAttribute.weight === null) {
      targetedAttribute.weight = 0;
    }

    let unlockedAttributes = this.analysis.attributes.filter(attrib => !attrib.isLocked && attrib.attributeId != targetedAttribute.attributeId);
    let sumOfUnlocked = unlockedAttributes.reduce((sum, item) => sum + item.weight, 0);

    let unadjustedSum = this.analysis.attributes.reduce((sum, item) => sum + item.weight, 0);
    let toDistribute = 100 - unadjustedSum;

    unlockedAttributes.forEach(attribute => {
      let relativeRatio = sumOfUnlocked > 0 ? attribute.weight / sumOfUnlocked : 1 / unlockedAttributes.length;
      attribute.weight += (toDistribute * relativeRatio);
      if (attribute.weight > 100) { attribute.weight = 100; }
      if (attribute.weight < 0) { attribute.weight = 0; }
    });
  };

  this.setLock = (targetedAttribute, bypassMouseCheck=false) => {
    if (!bypassMouseCheck) {
      if (!this.attributeMouseEvents[targetedAttribute.id]) {
        return;
      }
      this.attributeMouseEvents[targetedAttribute.id] = false;
    }

    let unlockedAttributes = this.analysis.attributes.filter(attrib => !attrib.isLocked && attrib.attributeId != targetedAttribute.attributeId);

    // first handle decimal values remainders
    unlockedAttributes.forEach(attribute => {
      attribute.weight = Math.floor(attribute.weight);
    });

    // second handle remainders
    let balance = 100 - this.analysis.attributes.reduce((sum, item) => sum + item.weight, 0);
    let index = 0;
    while (balance > 0 && index < unlockedAttributes.length) {
      unlockedAttributes[index++].weight += 1;
      balance--;
    }

    // third lock the attribute just selected and push the updated list to the server
    targetedAttribute.isLocked = true;
    this.updateAttributeList();
  };

  this.updateAttributeList = () => {
    this.checkForErrors();

    vaxAnalyzeAttributeService.updateList({
      id: this.analysisId,
      attributes: this.analysis.attributes
    }).then(() => {
      this.updatePercentComplete();
    });
  };

  this.removeAttribute = (attribute) => {
    this.analysis.attributes.splice(this.analysis.attributes.indexOf(attribute), 1);

    let indexOfUserAttribute = this.userAttributes.map(attr => attr.id).indexOf(attribute.id);
    if (indexOfUserAttribute !== -1) {
      this.userAttributes.splice(indexOfUserAttribute, 1);
    }

    this.checkIsCentroid(false);
    this.updateWeights(attribute);
    this.setLock(attribute, true);
    this.checkForErrors();
    this.updatePercentComplete();
  };

  this.profileSortOptions = {
    orderChanged: (event) => {
      let newSortIndex = 0;
      event.dest.sortableScope.modelValue.forEach(profile => {
        profile.sortOrder = newSortIndex++;
      });

      this.updateProfileList();
    },

    placeholder:
`<div class="profile">
  <div class="profile-summary">
    <div class="profile-reorder"></div>
    <div class="profile-meta">
      <h3 class="profile-title">Reorder Selected Profiles</h3>
      <div class="profile-detail">Drop any analysis profile here to update the sorting order for analysis.</div>
    </div>
  </div>
</div>`
  };

  this.attributeSortOptions = {
    orderChanged: (event) => {
      let newSortIndex = 0;
      event.dest.sortableScope.modelValue.forEach(attribute => {
        attribute.sortOrder = newSortIndex++;
      });

      if (this.analysis.isCentroid) {
        this.applyRankOrderCentroid();
      }

      this.updateAttributeList();
    },

    placeholder:
`<div class="attribute">
  <div class="attribute-summary">
    <div class="attribute-reorder"></div>
    <div class="attrbute-meta">
      <label class="control-label">Reorder Selected Attributes</h3>
      <div>Drop any attribute here to update the sorting order for analysis.</div>
    </div>
  </div>
</div>`
  };

  this.addProfile = (ev) => {
    let addProfileInstance = $mdDialog.show({
      templateUrl:  'templates/dialog/analyze-profile-create.html',
      controller:   'vaxAnalysisProfileCreateController',
      controllerAs: 'analyzeController',
      clickOutsideToClose: true,
      fullscreen: true,
      parent: angular.element($window.document.body),
      targetEvent: ev,
      resolve: {
        analysisId: () => this.analysisId
      }
    });

    let controller = this;
    addProfileInstance.then((result) => {
      vaxAnalyzeProfileService.create(result).then((response) => {
        if (response.analysisId != this.analysisId) {
          $location.path(`/analyze/${response.analysisId}`);
        } else {
          controller.refreshData();
        }
      });
    });
  };

  this.addAttribute = (ev) => {
    let addAttributeInstance = $mdDialog.show({
      templateUrl:  'templates/dialog/analyze-attribute-select.html',
      controller:   'vaxAnalysisAttributeSelectController',
      controllerAs: 'analyzeController',
      clickOutsideToClose: true,
      fullscreen: true,
      parent: angular.element($window.document.body),
      targetEvent: ev,
      resolve: {
        analysisId: () => this.analysisId
      }
    });

    addAttributeInstance.then((result) => {
      let selectedAttributes = result;
      let selectedIds = [];

      for (let id in selectedAttributes) {
        if (selectedAttributes.hasOwnProperty(id) && selectedAttributes[id]) {
          selectedIds.push(id);
        }
      }

      let saveRequest = {
        analysisId: this.analysisId || null,
        attributeIds: selectedIds
      };

      vaxAnalyzeAttributeService.create(saveRequest).then((response) => {
        if (response.analysisId != this.analysisId) {
          $location.path(`/analyze/${response.analysisId}`);
        } else {
          this.refreshData();
        }
      });
    });
  };

  this.addProfileCitation = ($event, form, entity, citation) => {
    vaxAnalyzeProfileService.addCitation(entity, citation).then(response => {
      let newCitation = angular.copy(response);
      entity.citations.analysisProfile.push(newCitation);

      Object.keys(citation).forEach(function(key) { delete citation[key]; });
      form.$setPristine();
      form.$setUntouched();
    }, reason => {
      logService.displayErrorMessage(reason, 'Unable to add the Citation.');
    });
  };

  this.removeProfileCitation = ($event, entity, citation) => {
    vaxAnalyzeProfileService.removeCitation(entity, citation).then(() => {
      let index = entity.citations.analysisProfile.indexOf(citation);
      entity.citations.analysisProfile.splice(index, 1);
    }, reason => {
      logService.displayErrorMessage(reason, 'Unable to remove the Citation.');
    });
  };

  this.addNote = ($event, form, entity, note) => {
    vaxAnalysisService.addNote(entity, note).then(response => {
      let newNote = angular.copy(response);
      entity.notes.push(newNote);

      Object.keys(note).forEach(function(key) { delete note[key]; });
      form.$setPristine();
      form.$setUntouched();
    }, reason => {
      logService.displayErrorMessage(reason, 'Unable to add the note.');
    });
  };

  this.removeNote = ($event, entity, note) => {
    vaxAnalysisService.removeNote(entity, note).then(() => {
      let index = entity.notes.indexOf(note);
      entity.notes.splice(index, 1);
    }, reason => {
      logService.displayErrorMessage(reason, 'Unable to remove the note.');
    });
  };

  this.runReport = () => {
    vaxReportService.create({ analysisId: this.analysisId}).then(() => {
      $location.path(`/report/${this.analysisId}`);
    });
  };
}
