export default function ReportDetailsController(_, $log, $mdDialog, $window, reportId, vaxReportService) {
  this.reportId = reportId;
  this.report = {};

  vaxReportService.getDetails(this.reportId).then((response) => {
    angular.copy(response, this.report);

    this.report.profiles.forEach(profile => {
      profile.citations.combined = _.chain(profile.citations)
        .map()
        .flatten()
        .uniqBy('id')
        .value();
    });
  }, (reason) => {
    $log.error(reason);
  });

  this.print = () => {
    $window.print();
  };

  this.cancel = () => {
    return $mdDialog.cancel();
  };
}
