export default function ItemRenameController($mdDialog, $mdConstant, $log, currentName) {
  this.entity = {
    name: currentName
  };

  this.keypress = ($event) => {
    if ($event.keyCode === $mdConstant.KEY_CODE.ENTER) {
      return $mdDialog.hide(this.entity);
    }
  };

  this.ok = () => {
    return $mdDialog.hide(this.entity);
  };

  this.cancel = () => {
    return $mdDialog.cancel();
  };
}

