export default function AccountOrganizationService(vaxOrganizationApi, logService) {
  return {
    get: () => {
      let defer = vaxOrganizationApi.find();
      return defer;
    },

    save: (organization) => {
      let defer = vaxOrganizationApi.save(organization);

      defer.then(() => {
        //orbMessageService.success('Organization updated.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred updating this Organization.');
      });

      return defer;
    }
  };
}

