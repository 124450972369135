export default function AttributeService(vaxAttributeApi, logService, orbMessageService) {
  return {
    empty: () => {
      return vaxAttributeApi.empty();
    },

    all: () => {
      let defer = vaxAttributeApi.all();
      return defer;
    },

    get: (id) => {
      let defer = vaxAttributeApi.find(id);
      return defer;
    },

    create: (attribute) => {
      let defer = vaxAttributeApi.save(attribute);

      defer.then(() => {
        orbMessageService.success('Attribute created.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred creating this Attribute.');
      });

      return defer;
    },

    save: (attribute) => {
      let defer = vaxAttributeApi.save(attribute);

      defer.then(() => {
        //orbMessageService.success('Attribute updated.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred updating this Attribute.');
      });

      return defer;
    },

    delete: (attribute) => {
      let defer = vaxAttributeApi.delete(attribute.id);

      defer.then(() => {
        //orbMessageService.success('Attribute updated.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred deleting this Attribute.');
      });

      return defer;
    }
  };
}
