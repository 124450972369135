export default function AnalysisProfileCreateController(analysisId, $log, $mdDialog) {
  this.profile = {
    analysisId: analysisId,
    name: null,
    description: null
  };

  this.save = () => {
    return $mdDialog.hide(this.profile);
  };

  this.cancel = () => {
    return $mdDialog.cancel();
  };
}
