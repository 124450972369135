export default function AnalyzeProfileService(vaxAnalyzeProfileApi, vaxAnalyzeFilterApi, vaxAnalysisProfileListApi, logService, orbMessageService) {
  return {
    create: (profile) => {
      let defer = vaxAnalyzeProfileApi.save(profile);

      defer.then((response) => {
        orbMessageService.success('Profile added.');
        return response;
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred while adding this Profile to the analysis.');
      });

      return defer;
    },

    save: (profile) => {
      let defer = vaxAnalyzeProfileApi.save(profile);

      defer.then((response) => {
        // update successful
        return response;
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred while updating the Profile configuration.');
      });

      return defer;
    },

    delete: (profile) => {
      let defer = vaxAnalyzeProfileApi.delete(profile.id);

      defer.then(() => {
        // delete successful
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred while attempting to delete the Profile.');
      });

      return defer;
    },

    addCitation: (profile, citation) => {
      let defer = vaxAnalyzeProfileApi.addCitation({id: profile.id}, citation);

      defer.then(() => {
        //orbMessageService.success('Analysis Profile citation added.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred adding this citation.');
      });

      return defer;
    },

    removeCitation: (profile, citation) => {
      let defer = vaxAnalyzeProfileApi.removeCitation({id: profile.id, citationId: citation.id});

      defer.then(() => {
        //orbMessageService.success('Analysis Profile citation remove.');
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred removing this citation.');
      });

      return defer;
    }
  };
}
