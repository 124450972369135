export default function ItemCreateController($mdDialog, $mdConstant) {
  this.newEntity = {};

  this.keypress = ($event) => {
    if ($event.keyCode === $mdConstant.KEY_CODE.ENTER) {
      return $mdDialog.hide(this.newEntity);
    }
  };

  this.ok = () => {
    return $mdDialog.hide(this.newEntity);
  };

  this.cancel = () => {
    return $mdDialog.cancel();
  };
}
