export default function AccountProfileService(vaxAccountProfileApi, logService, orbMessageService) {
  let profileData = {};

  return {
    data: profileData,

    refresh: () => {
      let defer = vaxAccountProfileApi.all();
      defer.then((updatedProfile) => {
        if (updatedProfile !== profileData) {
          angular.copy(updatedProfile, profileData);
        }
      });

      return defer;
    },

    save: () => {
      let defer = vaxAccountProfileApi.save(profileData);
      defer.then(() => {
        orbMessageService.success('Profile saved.');
      }, reason => {
        logService.displayErrorMessage(reason, 'An error occurred while saving your profile.');
      });

      return defer;
    }
  };
}
