export default function ThemingProvider($mdThemingProvider, $PRIORITYVAX_THEME_CSS) {
  $mdThemingProvider.registerStyles($PRIORITYVAX_THEME_CSS);

  $mdThemingProvider.definePalette('blue', {
    '50':   '#e3edf5',
    '100':  '#bad3e6',
    '200':  '#8cb5d6',
    '300':  '#5e97c5',
    '400':  '#3c81b8',
    '500':  '#196bac',
    '600':  '#1663a5',
    '700':  '#12589b',
    '800':  '#0e4e92',
    '900':  '#083c82',
    'A100': '#70bde5',
    'A200': '#32a0da',
    'A400': '#268ed0',
    'A700': '#1373c2',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': [
      '50',
      '100',
      '200',
      '300',
      'A100',
      'A200'
    ],
    'contrastLightColors': [
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
      'A400',
      'A700'
    ]
  });

  $mdThemingProvider.definePalette('orange', {
    '50':   '#fdf1e4',
    '100':  '#f9dbbb',
    '200':  '#f6c48e',
    '300':  '#f2ac61',
    '400':  '#ef9a3f',
    '500':  '#ec881d',
    '600':  '#ea801a',
    '700':  '#e77515',
    '800':  '#e46b11',
    '900':  '#df580a',
    'A100': '#f1cabe',
    'A200': '#de8268',
    'A400': '#d04d27',
    'A700': '#c43d1d',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': [
      '50',
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      'A100',
      'A200'
    ],
    'contrastLightColors': [
      '900',
      'A400',
      'A700'
    ]
  });

  $mdThemingProvider.definePalette('old-red', {
    '50':   '#fbecec',
    '100':  '#f6d1d1',
    '200':  '#f0b2b2',
    '300':  '#e99393',
    '400':  '#e57b7b',
    '500':  '#e06464',
    '600':  '#dc5c5c',
    '700':  '#d85252',
    '800':  '#d34848',
    '900':  '#cb3636',
    'A100': '#ffe5e8',
    'A200': '#ffa8b3',
    'A400': '#ff8e9d',
    'A700': '#ff7f8f',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': [
      '50',
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      'A100',
      'A200',
      'A400',
      'A700'
    ],
    'contrastLightColors': [
      '700',
      '800',
      '900'
    ]
  });
  $mdThemingProvider.definePalette('old-green', {
    '50':   '#eef9e9',
    '100':  '#d4f1c8',
    '200':  '#b7e7a4',
    '300':  '#9add7f',
    '400':  '#84d663',
    '500':  '#6ecf48',
    '600':  '#66ca41',
    '700':  '#5bc338',
    '800':  '#51bd30',
    '900':  '#3fb221',
    'A100': '#f0ffec',
    'A200': '#c6ffb9',
    'A400': '#9cff86',
    'A700': '#87ff6d',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': [
      '50',
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      'A100',
      'A200',
      'A400',
      'A700'
    ],
    'contrastLightColors': [
      '900'
    ]
  });
  $mdThemingProvider.definePalette('blue-grey', {
    '50':   '#c9cfd5',
    '100':  '#9ea9b4',
    '200':  '#7e8e9b',
    '300':  '#5c6a76',
    '400':  '#4e5a65',
    '500':  '#414b54',
    '600':  '#343c43',
    '700':  '#262c31',
    '800':  '#191d20',
    '900':  '#0c0d0f',
    'A100': '#b3cbe1',
    'A200': '#6a99c3',
    'A400': '#486580',
    'A700': '#485867',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': [
      '50',
      '100',
      '200',
      'A100',
      'A200'
    ],
    'contrastLightColors': [
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
      'A400',
      'A700'
    ]
  });

  $mdThemingProvider.theme('default')
    .primaryPalette('blue')
    .accentPalette('orange', {
      'default' : '500',
      'hue-1'   : '300',
      'hue-2'   : '800',
      'hue-3'   : 'A100'
    })
    .warnPalette('red')
    .backgroundPalette('grey', {
      'default' : '50',
      'hue-1'   : '50'
    })
  ;

  $mdThemingProvider.theme('notify-info')
    .primaryPalette('grey', {
      'default' : '50'
    })
    .backgroundPalette('blue', {
      'default' : '500'
    })
    .dark()
  ;

  $mdThemingProvider.theme('notify-danger')
    .primaryPalette('grey', {
      'default' : '50'
    })
    .backgroundPalette('red', {
      'default' : '900'
    })
    .dark()
  ;

  $mdThemingProvider.theme('text-info')
    .primaryPalette('grey', {
      'default' : '500'
    })
  ;

  $mdThemingProvider.theme('text-success')
    .primaryPalette('green', {
      'default' : '900'
    })
  ;

  $mdThemingProvider.theme('text-danger')
    .primaryPalette('red', {
      'default' : '900'
    })
  ;

  $mdThemingProvider.theme('app-title')
    .primaryPalette('blue', {
      'default' : '500'
    })
    .accentPalette('orange')
    .warnPalette('red')
    .backgroundPalette('grey', {
      'default' : '50'
    })
  ;

  $mdThemingProvider.theme('app-nav')
    .dark()
    .primaryPalette('blue', {
      'default' : 'A200',
      'hue-1'   : 'A100',
      'hue-2'   : 'A400',
      'hue-3'   : 'A700'
    })
    .accentPalette('orange', {
      'default' : '500',
      'hue-1'   : 'A100',
      'hue-2'   : 'A400',
      'hue-3'   : 'A700'
    })
    .warnPalette('red')
    .backgroundPalette('blue-grey', {
      'default' : '600',
      'hue-1'   : '500',
      'hue-2'   : '700',
      'hue-3'   : 'A700'
    })
  ;
}

