export default function AttributeListController($window, $mdDialog, vaxAttributeService, logService) {
  vaxAttributeService.all().then(response => {
    this.data = response;
  }, reason => {
    logService.displayErrorMessage(reason, 'Your user-defined Attributes could not be loaded.');
  });

  this.getNewAttribute = (ev) => {
    let createModal = $mdDialog.show({
      templateUrl:  'templates/dialog/attribute-create.html',
      controller:   'vaxItemCreateController',
      controllerAs: 'createController',
      parent: angular.element($window.document.body),
      targetEvent: ev
    });

    createModal.then((newEntity) => {
      vaxAttributeService.create(newEntity).then((response) => {
        this.data.attributes.push(response);
      }, () => {
        // TODO: determine if anything needs to happen here; service logs error
      });
    });
  };
}
