export function NavigationPrimary() {
  return {
    restrict: 'A',
    templateUrl: 'templates/nav-primary.html',
    require: 'mdSidenav',
    controller: function NavigationPrimaryController($scope, $mdMedia, identityService) {
      identityService.ensure();

      this.user = identityService.data;
      this.isOpen = true;
      this.open = function() { $scope.sideNav.open(); };
      this.close = function() { $scope.sideNav.close(); };
      this.toggle = function() { $scope.sideNav.toggle(); };

      $scope.$mdMedia = $mdMedia;

      $scope.$watch(
        function isOpen() { return $scope.sideNav !== null && ( $scope.sideNav.isOpen() || $scope.sideNav.isLockedOpen() ); },
        (newVal) => { this.isOpen = newVal; }
      );
    },
    link: function NavigationPrimaryLink(scope, element, attrs, controller) {
      scope.sideNav = controller;
    },
    controllerAs: 'navController'
  };
}

export function AsMatchModel() {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      matchModelValue: "=asMatchModel"
    },
    link: function(scope, element, attributes, ngModel) {
      ngModel.$validators.asMatchModel = function(modelValue) {
        return modelValue === scope.matchModelValue;
      };

      scope.$watch("matchModelValue", function() {
        ngModel.$validate();
      });
    }
  };
}

export function AsLogoutDirective(identityService) {
  return {
    restrict: 'A',
    link: (scope, element) => {
      element.bind('click', () => {
        identityService.logout();
      });
    }
  };
}

export function StartFromFilter() {
  return (input, start) => {
    if (input && input.length > 0) {
      start = +start;
      return input.slice(start);
    }

    return [];
  };
}

export function IsEmptyFilter() {
  let prop;
  return function (obj) {
    for (prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  };
}

export function AlphanumericFilter() {
  return (input) => {
    return String(input).replace(/[^0-9a-z]/gi, '');
  };
}

export function AttributeCategoryFilter() {
  return (input) => {
    let attributeCategoryCodes = {
      0: "Uncategorized",
      1: "Demographic",
      2: "Economic",
      3: "Health",
      4: "Other Societal Values",
      5: "Policy",
      6: "Programmatic",
      7: "Public Concern",
      8: "Manufacturing & Supply"
    };

    return attributeCategoryCodes[input];
  };
}

export function YesNoFilter() {
  return (input) => {
    return input ? "Yes" : "No";
  };
}

export function NumberNoTrail($filter) {
  return (input, fractionSize=3) => {
    let stdFilterInput = $filter('number')(input, fractionSize);

    if (stdFilterInput.includes('∞')) { return stdFilterInput; }

    let parsedInput = parseFloat(stdFilterInput);
    return isNaN(parsedInput) ? stdFilterInput : parsedInput.toString();
  };
}

