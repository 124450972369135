export default function ReportNotesController($log, $mdDialog, analysisId, vaxReportService, vaxAnalysisService, logService) {
  this.analysisId = analysisId;
  this.analysis = {};

  vaxReportService.getDetails(this.analysisId).then((response) => {
    angular.copy(response, this.analysis);
  }, (reason) => {
    $log.error(reason);
  });

  this.addNote = ($event, form, entity, note) => {
    vaxAnalysisService.addNote(entity, note).then(response => {
      let newNote = angular.copy(response);
      entity.notes.push(newNote);

      Object.keys(note).forEach(function(key) { delete note[key]; });
      form.$setPristine();
      form.$setUntouched();
    }, reason => {
      logService.displayErrorMessage(reason, 'Unable to add the Note.');
    });
  };

  this.removeNote = ($event, entity, note) => {
    vaxAnalysisService.removeNote(entity, note).then(() => {
      let index = entity.notes.indexOf(note);
      entity.notes.splice(index, 1);
    }, reason => {
      logService.displayErrorMessage(reason, 'Unable to remove the Note.');
    });
  };

  this.cancel = () => {
    return $mdDialog.cancel();
  };
}

