export default function ReportController($scope, $routeParams, $q, $log, $window, $mdDialog, $location, vaxReportService, vaxComputationMonitor) {
  this.reportId = $routeParams.reportId;
  this.report = {};
  this.activeProfile = {};

  this.refreshData = () => {
    vaxReportService.get(this.reportId).then((response) => {
      angular.copy(response, this.report);

      if (this.activeProfile && this.activeProfile.id) {
        let priorSelected = this.report.profiles.find(profile => profile.id === this.activeProfile.id);
        priorSelected.active = true;
        angular.copy(priorSelected, this.activeProfile);
      } else {
        let selectedProfile = this.report.profiles.find(profile => !isNaN(parseFloat(profile.smartScore)) && isFinite(profile.smartScore));
        if (selectedProfile) { selectedProfile.active = true; }
        angular.copy(selectedProfile, this.activeProfile);
      }

    }, (reason) => {
      $log.error(reason);
    });
  };

  this.openMenu = ($mdMenu, $event) => {
    $mdMenu.open($event);
  };

  this.deleteReport = ($event) => {
    let deleteConfirm = $mdDialog.confirm()
      .title('Delete report?')
      .textContent(`All captured data for this report will be permanently deleted.`)
      .ariaLabel('Delete report?')
      .css('md-confirm-dialog')
      .targetEvent($event)
      .ok('Delete')
      .cancel('Cancel');

    $mdDialog.show(deleteConfirm).then(() => {
      vaxReportService.delete(this.report).then(() => $location.path('/analyze/load'));
    }, () => {
      // TODO: something went wrong. Log it.
    });
  };

  this.renameReport = ($event) => {
    let renameModal = $mdDialog.show({
      templateUrl:  'templates/dialog/report-rename.html',
      controller:   'vaxItemRenameController',
      controllerAs: 'renameController',
      clickOutsideToClose: true,
      parent: angular.element($window.document.body),
      targetEvent: $event,
      resolve: {
        currentName: () => this.report.name
      }
    });

    renameModal.then((values) => {
      this.updateReport(values);
    });
  };

  this.refreshData();

  this.setActive = (selectedProfile) => {
    this.report.profiles.forEach(profile => profile.active = false);

    selectedProfile.active = true;
    angular.copy(selectedProfile, this.activeProfile);
  };

  this.setHoverProfile = (selectedProfile) => {
    selectedProfile.hover = true;

    // skip if the hover is the already active profile
    if (selectedProfile.active) { return; }

    angular.copy(selectedProfile, this.activeProfile);
  };

  this.clearHoverProfile = (selectedProfile) => {
    selectedProfile.hover = false;

    // skip if this was the "clicked" active profile anyway
    if (this.activeProfile.active) { return; }

    angular.copy(this.report.profiles.find(profile => profile.active), this.activeProfile);
  };

  this.updateReport = (values) => {
    let defer = $q.defer();

    values = angular.extend({}, {
      id: this.report.id,
      name: this.report.name
    }, values);

    vaxReportService.save(values).then(() => {
      this.report = angular.extend(this.report, values);
      defer.resolve();
    }, reason => {
      $log.error(reason);
      defer.reject(reason.data); // prevent update to local model
    });

    return defer.promise;
  };

  this.reconfigure = (ev) => {
    let reconfigurationInstance = $mdDialog.show({
      templateUrl:  'templates/dialog/report-reconfigure.html',
      controller:   'vaxReportReconfigureController',
      controllerAs: 'reconfigureController',
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: true,
      parent: angular.element($window.document.body),
      targetEvent: ev,
      resolve: {
        analysisId: () => this.reportId
      }
    });

    reconfigurationInstance.then(() => {
      this.refreshData();
    });
  };

  this.citations = (ev) => {
    $mdDialog.show({
      templateUrl:  'templates/dialog/report-citations.html',
      controller:   'vaxReportCitationsController',
      controllerAs: 'citationsController',
      escapeToClose: true,
      clickOutsideToClose: false,
      parent: angular.element($window.document.body),
      targetEvent: ev,
      resolve: {
        analysisId: () => this.reportId
      }
    });
  };

  this.notes = (ev) => {
    $mdDialog.show({
      templateUrl:  'templates/dialog/report-notes.html',
      controller:   'vaxReportNotesController',
      controllerAs: 'notesController',
      escapeToClose: true,
      clickOutsideToClose: false,
      parent: angular.element($window.document.body),
      targetEvent: ev,
      resolve: {
        analysisId: () => this.reportId
      }
    });
  };

  this.details = (ev) => {
    $mdDialog.show({
      templateUrl:  'templates/dialog/report-details.html',
      controller:   'vaxReportDetailsController',
      controllerAs: 'detailsController',
      escapeToClose: true,
      clickOutsideToClose: false,
      parent: angular.element($window.document.body),
      targetEvent: ev,
      resolve: {
        reportId: () => this.reportId
      }
    });
  };

  let me = this;
  vaxComputationMonitor.subscribeTo(this.reportId, $scope, function() {
    $log.debug("Refreshing Report Data from SignalR Subscription");
    me.refreshData();
  }).then(() => {
    $log.debug("Refreshing Report Data after SignalR Subscription");
    me.refreshData();
  });
}
