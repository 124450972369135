export default function ReportReconfigureController(analysisId, $routeParams, $log, $mdDialog, $location, $q, vaxAnalysisService, vaxAnalyzeProfileService, vaxAnalyzeAttributeService, vaxAnalyzeValueService, vaxReportService) {
  this.analysisId = analysisId;
  this.analysis = {};
  this.userAttributes = [];
  this.hasMissingValues = true;

  this.attributeMouseEvents = {};

  this.hasWeightsError = false;

  this.refreshData = () => {
    vaxAnalysisService.getForReconfigure(this.analysisId).then((response) => {
      angular.copy(response, this.analysis);
      angular.copy(response.attributes.filter(attribute => [1,2].indexOf(attribute.typeCode) >= 0), this.userAttributes);

      this.analysis.profiles.forEach(profile => {
        this.reconcileProfileConfiguration(profile);
      });

      this.checkForErrors();

      this.checkIsCentroid(false);
    }, (reason) => {
      $log.error(reason);
    });
  };

  this.refreshData();

  this.reconcileProfileConfiguration = (profile) => {
    if (!profile.displayConfig) {
      profile.displayConfig = {};
    }

    profile.displayConfig.name = profile.name;
    profile.displayConfig.description = profile.description;
  };

  this.checkForErrors = () => {
    this.hasWeightsError = this.analysis.attributes.length && this.analysis.attributes.reduce((sum, item) => sum + item.weight, 0) !== 100;
  };

  this.updateProfileConfiguration = (analysisProfile, property, doValueUpdate, doSave) => {
    if (doValueUpdate) {
      analysisProfile[property] = analysisProfile.displayConfig[property];

      if (doSave) {
        this.saveConfiguration(analysisProfile);
      }
    }
  };

  this.toDefault = (analysisProfile, propertyName) => {
    if (analysisProfile.configuration[propertyName] !== null) {
      analysisProfile.configuration[propertyName] = null;
      this.reconcileProfileConfiguration(analysisProfile);

      for (let property in analysisProfile.configuration) {
        if (analysisProfile.configuration[property] === undefined) {
          return false;
        }
      }

      this.saveConfiguration(analysisProfile);
    }
  };

  this.saveConfiguration = (analysisProfile) => {
    let request = Object.assign({}, analysisProfile);
    request.analysisId = this.analysisId;

    vaxAnalyzeProfileService.save(request);
  };

  this.checkIsCentroid = (sendCentroidFlagToServer) => {
    if (sendCentroidFlagToServer) {
      vaxAnalysisService.save(this.analysis);
    }

    if (this.analysis.isCentroid) {
      this.analysis.attributes.forEach(attribute => {
        attribute.isLocked = true;
      });

      this.applyRankOrderCentroid();
    }
  };

  this.applyRankOrderCentroid = () => {
    if (!this.analysis.attributes.length) { return; }

    let numberOfItems = this.analysis.attributes.length;
    for (let i = 0; i < numberOfItems; i++) {
      let sigmaCounter = i+1;
      let weightNumerator = 0;
      while (sigmaCounter <= numberOfItems) {
        weightNumerator += (1 / sigmaCounter);
        sigmaCounter++;
      }
      let weightQuotient = weightNumerator * (1 / numberOfItems);
      this.analysis.attributes[i].weight = Math.floor(weightQuotient * 100);
    }

    let sumOfWeights = 0;
    for (let i = 0; i < numberOfItems; i++) {
      sumOfWeights += this.analysis.attributes[i].weight;
    }
    let difference = 100 - sumOfWeights;

    let index = 0;
    while (difference > 0) {
      this.analysis.attributes[index].weight += 1;
      difference--;
      index++;
    }

    this.updateAttributeList();
  };

  this.updateValues = (profile) => {
    let request = Object.assign({}, profile);
    request.analysisId = this.analysisId;

    vaxAnalyzeValueService.save(request);
  };

  this.updateProfileList = () => {
    vaxAnalyzeProfileService.updateList({
      id: this.analysisId,
      profiles: this.analysis.profiles
    });
  };

  this.setMouse = (id) => {
    this.attributeMouseEvents[id] = true;
  };

  this.updateWeights = (targetedAttribute) => {
    if (targetedAttribute.weight === undefined) {
      return;
    }
    if (targetedAttribute.weight === null) {
      targetedAttribute.weight = 0;
    }

    let unlockedAttributes = this.analysis.attributes.filter(attrib => !attrib.isLocked && attrib.attributeId != targetedAttribute.attributeId);
    let sumOfUnlocked = unlockedAttributes.reduce((sum, item) => sum + item.weight, 0);

    let unadjustedSum = this.analysis.attributes.reduce((sum, item) => sum + item.weight, 0);
    let toDistribute = 100 - unadjustedSum;

    unlockedAttributes.forEach(attribute => {
      let relativeRatio = sumOfUnlocked > 0 ? attribute.weight / sumOfUnlocked : 1 / unlockedAttributes.length;
      attribute.weight += (toDistribute * relativeRatio);
      if (attribute.weight > 100) { attribute.weight = 100; }
      if (attribute.weight < 0) { attribute.weight = 0; }
    });
  };

  this.setLock = (targetedAttribute, bypassMouseCheck=false) => {
    if (!bypassMouseCheck) {
      if (!this.attributeMouseEvents[targetedAttribute.id]) {
        return;
      }
      this.attributeMouseEvents[targetedAttribute.id] = false;
    }

    let unlockedAttributes = this.analysis.attributes.filter(attrib => !attrib.isLocked && attrib.attributeId != targetedAttribute.attributeId);

    // first handle decimal values remainders
    unlockedAttributes.forEach(attribute => {
      attribute.weight = Math.floor(attribute.weight);
    });

    // second handle remainders
    let balance = 100 - this.analysis.attributes.reduce((sum, item) => sum + item.weight, 0);
    let index = 0;
    while (balance > 0 && index < unlockedAttributes.length) {
      unlockedAttributes[index++].weight += 1;
      balance--;
    }

    // third lock the attribute just selected and push the updated list to the server
    targetedAttribute.isLocked = true;
    this.updateAttributeList();
  };

  this.updateAttributeList = () => {
    this.checkForErrors();

    vaxAnalyzeAttributeService.updateList({
      id: this.analysisId,
      attributes: this.analysis.attributes
    });
  };

  this.profileSortOptions = {
    orderChanged: (event) => {
      let newSortIndex = 0;
      event.dest.sortableScope.modelValue.forEach(profile => {
        profile.sortOrder = newSortIndex++;
      });

      this.updateProfileList();
    },

    placeholder:
`<div class="profile">
  <div class="profile-summary">
    <div class="profile-reorder"></div>
    <div class="profile-meta">
      <h3 class="profile-title">Reorder Selected Profiles</h3>
      <div class="profile-detail">Drop any analysis profile here to update the sorting order for analysis.</div>
    </div>
  </div>
</div>`
  };

  this.attributeSortOptions = {
    orderChanged: (event) => {
      let newSortIndex = 0;
      event.dest.sortableScope.modelValue.forEach(attribute => {
        attribute.sortOrder = newSortIndex++;
      });

      if (this.analysis.isCentroid) {
        this.applyRankOrderCentroid();
      }

      this.updateAttributeList();
    },

    placeholder:
`<div class="attribute">
  <div class="attribute-summary">
    <div class="attribute-reorder"></div>
    <div class="attrbute-meta">
      <label class="control-label">Reorder Selected Attributes</h3>
      <div>Drop any attribute here to update the sorting order for analysis.</div>
    </div>
  </div>
</div>`
  };

  this.ok = () => {
    vaxReportService.create({ analysisId: this.analysisId}).then(() => {
      return $mdDialog.hide();
    });
  };
}
