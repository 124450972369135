export default function AnalyzeAttributeService(vaxAnalyzeAttributeApi, vaxAnalysisAttributeListApi, logService, orbMessageService) {
  return {
    all: (analysisId) => {
      let defer = vaxAnalyzeAttributeApi.all({analysisId: analysisId});
      return defer;
    },

    create: (selectedAttributesRequest) => {
      let defer = vaxAnalyzeAttributeApi.save(selectedAttributesRequest);

      defer.then((response) => {
        orbMessageService.success('Attributes selected for analysis.');
        return response;
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred while adding these Attributes to your analysis.');
      });

      return defer;
    },

    updateList: (attributeListRequest) => {
      let defer = vaxAnalysisAttributeListApi.save(attributeListRequest);

      defer.then(() => {
        // successful
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred reordering the attributes.');
      });

      return defer;
    }
  };
}
