export default function AnalysisListController($location, $mdDialog, vaxAnalysisService, logService) {
  vaxAnalysisService.all().then((response) => {
    this.analyses = response.analyses || [];
  }, (reason) => {
    logService.displayErrorMessage(reason, 'Analyses could not be loaded.');
  });

  this.cloneFrom = (ev, analysis) => {
    vaxAnalysisService.cloneFrom(analysis).then((response) => {
      $location.path(`/analyze/${response.analysisId}`);
    });
  };

  this.deleteAnalysis = (ev, analysis) => {
    let deleteConfirm = $mdDialog.confirm()
      .title('Delete analysis?')
      .textContent(`All data and reports for this analysis will be permanently deleted.`)
      .ariaLabel('Delete analysis?')
      .css('md-confirm-dialog')
      .targetEvent(ev)
      .ok('Confirm')
      .cancel('Cancel');

    $mdDialog.show(deleteConfirm).then(() => {
      for (let analysisIndex = this.analyses.length; analysisIndex >= 0; analysisIndex--) {
        if (this.analyses[analysisIndex] !== analysis) { continue; }
        this.analyses.splice(analysisIndex, 1);
      }
      vaxAnalysisService.delete(analysis);
    }, () => {
      // TODO: something went wrong. Log it.
    });
  };
}
