export default function AnalyzeValueService(vaxAnalyzeValueApi, logService) {
  return {
    save: (request) => {
      let defer = vaxAnalyzeValueApi.save(request);

      defer.then(() => {
        // successful update
      }, (reason) => {
        logService.displayErrorMessage(reason, 'An error occurred while update the values for this profile.');
      });

      return defer;
    }
  };
}
