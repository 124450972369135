export default function RegisterController($q, $location, identityService) {
  this.credentials = {
    fullName: '',
    email: '',
    password: ''
  };

  this.save = () => {
    let registration = identityService.register(this.credentials);

    $q.when(registration).then(() => {
      $location.path('/');
    });
  };
}
